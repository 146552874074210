import { Component, OnInit } from '@angular/core';
import { ViewportScroller } from "@angular/common";

@Component({
  selector: 'app-philosophy',
  templateUrl: './philosophy.component.html',
  styleUrls: ['./philosophy.component.scss']
})
export class PhilosophyComponent implements OnInit {

  constructor( private viewportScroller: ViewportScroller) {}

  onClickScroll(elementID: string): void {
    this.viewportScroller.scrollToAnchor(elementID);
  }

  ngOnInit() {
  }

}

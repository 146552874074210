import { Component, OnInit } from '@angular/core';
import { ViewportScroller } from "@angular/common";

@Component({
  selector: 'app-calender',
  templateUrl: './calender.component.html',
  styleUrls: ['./calender.component.scss']
})
export class CalenderComponent implements OnInit {

  constructor( private viewportScroller: ViewportScroller) {}

  onClickScroll(elementID: string): void {
    this.viewportScroller.scrollToAnchor(elementID);
  }

  ngOnInit() {
    window.Calendly.initInlineWidget({
      url: 'https://calendly.com/unionsquarept/pt?hide_event_type_details=1&hide_gdpr_banner=1"',
      parentElement: document.querySelector('.calendly-inline-widget'),
    });
  }

}
export {}; declare global { interface Window { Calendly: any; } }

import { Component, OnInit } from '@angular/core';
import {IImage} from 'ng-simple-slideshow';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss']
})
export class SliderComponent implements OnInit {

  imageUrls: (string | IImage)[] = [
    { url: '/assets/images/hero-1.jpg', caption: 'Physical Therapy services available in Union Square, Somerville, MA', href: '' },
    { url: '/assets/images/hero-2.jpg', caption: 'Feel your best starting today!', href: '' },
    { url: '/assets/images/hero-3.jpg', caption: 'Book an appointment with us now!', href: '#calendar' },
    { url: '/assets/images/hero-4.jpg', caption: 'Chronic pain? We can help!', href: '' }
  ];
  constructor() { }

  ngOnInit() {
  }

}

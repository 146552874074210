import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';



import { LocationsComponent } from './locations/locations.component';

import { FooterComponent } from './footer/footer.component';
import { SliderComponent } from './slider/slider.component';
import { SlideshowModule } from 'ng-simple-slideshow';
import { CalenderComponent } from './calender/calender.component';
import { TestimonialsComponent } from './testimonials/testimonials.component';
import { PhilosophyComponent } from './philosophy/philosophy.component';
import { ServicesComponent } from './services/services.component';
import { NewPatientComponent } from './new-patient/new-patient.component';
import { AdvantageComponent } from './advantage/advantage.component';
import { FaqComponent } from './faq/faq.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LocationsComponent,
    FooterComponent,
    SliderComponent,
    CalenderComponent,
    TestimonialsComponent,
    PhilosophyComponent,
    ServicesComponent,
    NewPatientComponent,
    AdvantageComponent,
    FaqComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SlideshowModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }


import { Component, OnInit } from '@angular/core';
import {ViewportScroller} from "@angular/common";

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements OnInit {

  constructor( private viewportScroller: ViewportScroller) {}

  onClickScroll(elementID: string): void {
    this.viewportScroller.scrollToAnchor(elementID);
  }

  ngOnInit(): void {

  }

}
